import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  OnInit,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[appReadMore]',
})
export class ReadMoreDirective implements OnInit {
  @Input('appReadMoreFullText') fullText: string = ''; // Full HTML content
  @Input('appReadMoreMaxLength') maxLength: number = 100; // Maximum length

  isCollapsed: boolean = true;
  originalText: string = '';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
      this.originalText = this.fullText; // Save the original HTML content
      this.updateText();
  }

  @HostListener('click')
  toggleText() {
      this.isCollapsed = !this.isCollapsed;
      this.updateText();
  }

  private updateText() {
      // Get the plain text content from HTML
      const plainText = this.getTextContent(this.originalText);

      // Check if truncation is needed based on text length
      if (plainText.length > this.maxLength) {
          const truncatedHtml = this.isCollapsed
              ? this.truncateHtml(this.originalText, this.maxLength) + '...'
              : this.originalText;

          const toggleText = this.isCollapsed ? 'Read More' : 'Show Less';

          this.renderer.setProperty(
              this.el.nativeElement,
              'innerHTML',
              `${truncatedHtml} <span style="color: #028be5; cursor: pointer;">${toggleText}...</span>`
          );
      } else {
          this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.originalText);
      }
  }

  // Function to extract plain text content from HTML
  private getTextContent(html: string): string {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      return tempDiv.textContent || tempDiv.innerText || '';
  }

  // Function to truncate HTML content without breaking the structure
  private truncateHtml(html: string, maxLength: number): string {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      const text = tempDiv.textContent || tempDiv.innerText || '';

      if (text.length <= maxLength) return html;

      const truncatedText = text.substring(0, maxLength);

      const walkNode = (node: any) => {
          let result = '';
          for (let child of node.childNodes) {
              if (child.nodeType === Node.TEXT_NODE) {
                  const remainingLength = maxLength - result.length;
                  result += child.nodeValue.substring(0, remainingLength);
                  maxLength -= child.nodeValue.length;
                  if (result.length >= truncatedText.length) break;
              } else if (child.nodeType === Node.ELEMENT_NODE) {
                  const inner = walkNode(child);
                  result += `<${child.nodeName.toLowerCase()} ${Array.from(child.attributes).map(
                      (attr: any) => `${attr.name}="${attr.value}"`
                  )}>${inner}</${child.nodeName.toLowerCase()}>`;
                  if (result.length >= truncatedText.length) break;
              }
          }
          return result;
      };

      return walkNode(tempDiv);
  }
}
