import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
    feedback: FormControl = new FormControl('');
    constructor(private dialog: MatDialogRef<FeedbackComponent>) {}

    submitFeedback() {
        this.dialog.close(this.feedback.value ? this.feedback.value : '');
    }
}
