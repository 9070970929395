import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatRoutingModule } from './chat-routing.module';
import { ChatComponent } from './chat.component';
import { ChatContentComponent } from './chat-content/chat-content.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatService } from './services/chat.service';
import { MatMenuModule } from '@angular/material/menu';
import { FeedbackComponent } from './feedback/feedback.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TruncatePipe } from 'src/app/core/pipe/truncate.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReadMoreDirective } from './directive/read-more.directive';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
    declarations: [
        ChatComponent,
        ChatContentComponent,
        FeedbackComponent,
        ReadMoreDirective,
    ],
    imports: [
        CommonModule,
        ChatRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
    ],
    exports: [ChatComponent],
    providers: [
        // ChatService, 
        TruncatePipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChatModule {}
