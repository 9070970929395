export const environment = {
    baseURL: 'https://api-dev.crizac.com/v1/api/',
    fileBaseURL: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/system',
    callBaseURL: 'https://www.crizac.com/yeasterdownload/',
    validatePassportURL:
        'https://nps2entus1.execute-api.ap-south-1.amazonaws.com/default/ocr',
    currentLocationURL: 'https://ipapi.co/json/',
    basePHPURL: 'https://accounts.crizac.com/api_mongo/agent',
    baseDevPHPURL: 'https://admin.crizac.com/apimongo',
    apiPHPURLv2: 'https://admin.crizac.com/apimongo/',
    paypalClientId:
        'AeEifrmeT8xMflSOSZf6jFQMGMAVarQI_3LAnoPMgjQfITNyl1bBTBSUdYUyXjgysRc0Klp7Cgi71ZIu',
    paymentPercentage: 10,
    chatApi: 'wss://api-dev.crizac.com',
    redirectionPassword: 'crizac123',
    mailDomain: 'crizac.com',
    showCourses:true,
    showUniversity: true,
};
