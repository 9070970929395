import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { SocketService } from 'src/app/core/services/socket/socket.service';
import { DatePipe } from '@angular/common';
import { AudioRecordingService } from '../services/audio-recording.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { chatApi } from 'src/app/core/constants/api-constant/chat-api.const';
import { ChatService } from '../services/chat.service';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { filePathWithBaseURL } from 'src/app/core/helpers/helper.function';
import { TruncatePipe } from 'src/app/core/pipe/truncate.pipe';
import { Router } from '@angular/router';
import { StorageConst } from 'src/app/core/constants/storage.const';
import { LocalStorageService } from 'src/app/core/services/localStorage/localStorage.service';

@Component({
    selector: 'app-chat-content',
    templateUrl: './chat-content.component.html',
    styleUrls: ['./chat-content.component.scss'],
})
export class ChatContentComponent
    implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy
{
    @Input('showChatBody') showChatBody: boolean = true;
    @Input('chatRoomId') chatRoomId: string = '';
    @Input('userDetails') userDetails: any;
    @Input('isApplicationHistory') isApplicationHistory: any;
    @Input() withLogin: boolean;
    @Output() resetConversion: EventEmitter<{
        status: boolean;
        feedback: string;
    }> = new EventEmitter<{ status: boolean; feedback: string }>();

    @Output() senderSenderName: EventEmitter<string> =
        new EventEmitter<string>();

    @ViewChild('textarea') textareaRef: ElementRef<HTMLTextAreaElement>;
    @ViewChild('chatContent') chatContent: ElementRef;
    @ViewChildren('audioElementRef')
    audioElementRef: QueryList<ElementRef>;
    @ViewChildren('playButtonRef') playButtonRef: QueryList<ElementRef>;
    @Input() appFileUpload: HTMLInputElement;
    file_path_with_base_url = filePathWithBaseURL;

    private _unsubscribeAll: Subject<any> = new Subject();

    textInput: FormControl = new FormControl('');
    messages: any[] = [];
    showSpinner: boolean = false;

    fileName: string = '';
    fileType: string = '';
    file: any;
    base64Image: any = [];
    isSend: boolean = false;
    isAudioRecording: boolean = false;
    audioBlobUrl: any = null;
    audioBlob: any;
    audioName: any;
    audioRecordedTime: string;
    currentTimes: number[] = [];
    script: HTMLScriptElement;
    showEmoji: boolean = false;
    private boundHandleEmojiClick: (event: Event) => void;
    selectedEmoji: any;
    feedbackCount:number = 0;
    totalFeebackStarts: any  = [
        {id:1, starvalue:1, filled:false},
        {id:2, starvalue:1, filled:false},
        {id:3, starvalue:1, filled:false},
        {id:4, starvalue:1, filled:false},
        {id:5, starvalue:1, filled:false}
    ];
    showFeedbackStars:boolean = false;

    constructor(
        private _socketService: SocketService,
        private _chatService: ChatService,
        private date: DatePipe,
        private audioRecordingService: AudioRecordingService,
        private ref: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private zone: NgZone,
        private dialog: MatDialog,
        private truncate: TruncatePipe,
        private _router: Router,
        private localStorageService: LocalStorageService
    ) {
        this.loadScript();
    }    

    ngOnInit(): void {
        const urlSegments = this._router.url.split('/');
        const currentPage = urlSegments[3] || '';

        this.isApplicationHistory =
            currentPage && currentPage === 'view-application';

        if(this.withLogin){
            this.getInitalData();
        }else{
            this.getInitalDataWithoutLogin()
        }
        this.audioRecordingService.recordingFailed().subscribe(() => {
            this.isAudioRecording = false;
            this.ref.detectChanges();
        });

        this.audioRecordingService.getRecordedTime().subscribe((time) => {
            this.audioRecordedTime = time;
            this.ref.detectChanges();
        });

        this.audioRecordingService.getRecordedBlob().subscribe((data) => {
            this.audioBlob = data.blob;
            this.audioName = data.title;
            this.audioBlobUrl = this.sanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(data.blob)
            );
            this.ref.detectChanges();
        });

        this._socketService
            .listenToChannel(chatApi.supportChatChannel)
            .subscribe((data) => {
                if (data?.referenceId !== this.chatRoomId) return;
                if (data?.userType !== 'agent') {
                    this.senderSenderName.emit(data?.sourceArray[0]?.name);
                }
                this.messages.push({
                    text: data?.description,
                    file: {
                        fileName: data?.file?.name,
                        file: this.file_path_with_base_url(data?.file?.path),
                        fileType: data?.file?.documentType,
                        isImage: /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i.test(
                            data?.file?.extension
                        ),
                        duration: data?.file?.duration,
                    },
                    isSend: data?.userType === 'agent',
                    isConfirm: data?.chatStatus === '1',
                    time: this.date.transform(data?.createdAt, 'HH:mm aa'),
                });
                setTimeout(() => {
                    this.scrollToBottom();
                }, 200);
            });
    }

    ngAfterViewInit(): void {
        this.scrollToBottom();
        setTimeout(() => {
            this.addEmojiListener();
        }, 200);
    }

    getInitalData() {
        this._chatService
            .getChatRoomMessages(this.chatRoomId)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (res) => {
                    if (
                        res?.statusCode === 200 &&
                        res?.success &&
                        res?.data &&
                        res?.data?.data &&
                        res?.data.data.chatList
                    ) {
                        res?.data.data.chatList.reverse().map((item) => {
                            this.senderSenderName.emit(
                                item?.sourceArray[0]?.name
                            );
                            this.messages.push({
                                text: item?.description,
                                file: {
                                    fileName: item?.file?.name,
                                    file: this.file_path_with_base_url(
                                        item?.file?.path
                                    ),
                                    fileType: item?.file?.documentType,
                                    isImage:
                                        /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i.test(
                                            item?.file?.extension
                                        ),
                                    duration: item?.file?.duration,
                                },
                                isSend: item?.userType === 'agent',
                                isConfirm: item?.chatStatus === '1',
                                time: this.date.transform(
                                    item?.createdAt,
                                    'HH:mm aa'
                                ),
                            });
                        });
                        setTimeout(() => {
                            this.scrollToBottom();
                        }, 200);
                    }
                },
        });
    }

    getInitalDataWithoutLogin() {
        let fetchedGeneratedId = JSON.parse(this.localStorageService.getKey(StorageConst.cz_temGenId));
        let payload = {
          tempLoginId: fetchedGeneratedId,
        }
        this._chatService
            .getMessageInChatRoomWithoutLogin(fetchedGeneratedId)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (res: any) => {
                    if (
                        res?.statusCode === 200 &&
                        res?.success &&
                        res?.data &&
                        res?.data?.data &&
                        res?.data.data.chatList
                    ) {
                        res?.data.data.chatList.reverse().map((item) => {
                            this.senderSenderName.emit(
                                item?.sourceArray[0]?.name
                            );
                            this.messages.push({
                                text: item?.description,
                                file: {
                                    fileName: item?.file?.name,
                                    file: this.file_path_with_base_url(
                                        item?.file?.path
                                    ),
                                    fileType: item?.file?.documentType,
                                    isImage:
                                        /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i.test(
                                            item?.file?.extension
                                        ),
                                    duration: item?.file?.duration,
                                },
                                isSend: item?.userType === 'agent',
                                isConfirm: item?.chatStatus === '1',
                                time: this.date.transform(
                                    item?.createdAt,
                                    'HH:mm aa'
                                ),
                            });
                        });
                        setTimeout(() => {
                            this.scrollToBottom();
                        }, 200);
                    }
                },
        });
    }

    addEmojiListener = () => {
        const emoji = document.querySelector('emoji-picker');
        if (emoji) {
            this.zone.runOutsideAngular(() => {
                emoji.addEventListener('emoji-click', (event: any) => {
                    const unicode = event?.detail?.unicode;
                    if (unicode) {
                        // Use Angular to update the view
                        this.insertEmoji(unicode);
                        // Optionally, hide emoji picker
                        this.showEmoji = false;
                    }
                });
            });
        }
    };

    insertEmoji(unicode: string): void {
        const textArea = document.getElementById('textarea');
        textArea.innerHTML = `${textArea.innerHTML}${unicode}`;
        this.showEmoji = false;
        // const currentValue = this.textInput.value;
        // const textArea = document.getElementById('textarea') as any; // or input, depending on your HTML
        // const start = textArea.selectionStart;
        // const end = textArea.selectionEnd;

        // const newValue =
        //     currentValue.slice(0, start) + unicode + currentValue.slice(end);

        // this.textInput.patchValue(newValue);

        // // Maintain cursor position
        // setTimeout(() => {
        //     textArea.setSelectionRange(
        //         start + unicode.length,
        //         start + unicode.length
        //     );
        // }, 0);
    }

    private loadScript(): void {
        const scriptUrl =
            'https://cdn.jsdelivr.net/npm/emoji-picker-element@^1/index.js';
        const existingScript = document.querySelector(
            `script[src="${scriptUrl}"]`
        );

        if (!existingScript) {
            this.script = document.createElement('script');
            this.script.src = scriptUrl;
            this.script.type = 'module';
            document.body.appendChild(this.script);
        }
    }

    private removeScript(): void {
        if (this.script) {
            document.body.removeChild(this.script);
        }
    }

    private removeEmojiListener(): void {
        const emoji = document.querySelector('emoji-picker');
        if (emoji) {
            emoji.removeEventListener(
                'emoji-click',
                this.boundHandleEmojiClick
            );
        }
    }

    ngAfterViewChecked() {
        this.audioElementRef.forEach(
            (audioElement: ElementRef, index: number) => {
                audioElement.nativeElement.addEventListener('ended', () => {
                    this.currentTimes[index] =
                        audioElement.nativeElement.currentTime;
                    this.resetPlayButton(index);
                });
            }
        );
    }

    resetPlayButton(index: number): void {
        const playButton = this.playButtonRef.toArray()[index]?.nativeElement;
        if (playButton) {
            playButton.innerText = 'play_circle'; // Reset button text to 'play_circle'
            playButton?.classList?.remove('text-danger');
        }
    }

    scrollToBottom(): void {
        const chat = this.chatContent?.nativeElement as HTMLElement;
        if (chat) {
            chat.scrollTop = chat?.scrollHeight;
        }
    }

    onTextareaInput(event: any) {
        const textArea = document.getElementById('textarea');
        if (textArea) {
            // this.textInput.setValue(content, { emitEvent: false });
            textArea.style.height = textArea.scrollHeight + 'px';

            if (event.inputType === 'deleteContentBackward') {
                textArea.style.height = textArea.scrollHeight + 'px';
            }
        }

        // const target = event.target as HTMLElement;
        // const content = target.innerHTML;
        // this.textInput.setValue(content, { emitEvent: false });
        // this.ref.detectChanges(); // Ensure change detection
    }

    triggerFileInput(fileInput: HTMLInputElement) {
        fileInput.click();
    }

    onFileSelected(event: Event) {
        this.isSend = false;
        const input = event.target as HTMLInputElement;
        if (input.files && input.files[0]) {
            const file = input.files[0];
            this.file = input.files[0];
            this.fileType = file.type;

            if (
                !this.fileType.startsWith('image/') &&
                this.fileType !== 'application/pdf'
            ) {
                return;
            }

            this.fileName = file.name;

            if (file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onload = () => {
                    this.base64Image = reader.result;
                };
                reader.readAsDataURL(file);
            }
        }
    }

    removeFile() {
        if (this.showSpinner) return;
        this.base64Image = [];
        this.fileName = '';
    }

    startAudioRecording() {
        if (!this.isAudioRecording) {
            this.isAudioRecording = true;
            this.audioRecordingService.startRecording();
        }
    }

    abortAudioRecording() {
        if (this.isAudioRecording) {
            this.isAudioRecording = false;
            this.audioRecordingService.abortRecording();
        }
    }

    stopAudioRecording() {
        if (this.isAudioRecording) {
            this.audioRecordingService.stopRecording();
            this.isAudioRecording = false;
        }
    }

    clearAudioRecordedData() {
        if (this.showSpinner) return;
        this.audioBlobUrl = null;
        this.audioBlob = null;
    }

    downloadAudioRecordedData() {
        this._downloadFile(this.audioBlob, 'audio/mp3', this.audioName);
    }

    _downloadFile(data: any, type: string, filename: string): any {
        const blob = new Blob([data], { type: type });
        const url = window.URL.createObjectURL(blob);
        //this.video.srcObject = stream;
        //const url = data;
        const anchor = document.createElement('a');
        anchor.download = filename;
        anchor.href = url;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    playAudio(index: any) {
        const audioElement = this.audioElementRef.toArray()[index]
            ?.nativeElement as HTMLAudioElement;
        const playButton = this.playButtonRef.toArray()[index]?.nativeElement;

        if (audioElement) {
            this.currentTimes[index] = audioElement.currentTime;
            if (audioElement.paused) {
                audioElement.play();
                if (playButton) {
                    playButton.innerText = 'stop_circle';
                    playButton?.classList?.add('text-danger');
                }
            } else {
                audioElement.pause();
                if (playButton) {
                    playButton.innerText = 'play_circle';
                    playButton?.classList?.remove('text-danger');
                }
            }
        } else {
            console.error(`Audio element at index ${index} not found.`);
        }
    }

    onPlayButtonClick(event: MouseEvent): void {
        const clickedElement = event.target as HTMLElement;
        const index = this.playButtonRef
            .toArray()
            .findIndex((el) => el.nativeElement === clickedElement);

        if (index !== -1) {
            this.playAudio(index);
        }
    }

    convertImageToBase64(imageUrl: string): Promise<string> {
        return fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
                return new Promise<string>((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result as string);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            });
    }

    videoMeeting() {
        const payload = {
            userId: this.userDetails?.userId,
            userName: `${this.userDetails?.firstName} ${this.userDetails?.lastName}`,
            roomId: this.chatRoomId,
        };

        this._chatService
            .createMeeting(payload)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (res: any) => {
                    if (res?.statusCode === 200 && res?.success && res?.data) {
                        const meetingLink = res?.data;
                        const imageUrl = 'assets/images/meeting.png';
                        this.convertImageToBase64(imageUrl)
                            .then((base64Image) => {
                                const htmlContent = `<a class="meeting" href="${meetingLink}" target="_blank">
                                <img src="${base64Image}" alt="Video Meeting" style="display: block;" />
                                <div>${
                                    this.truncate.transform(meetingLink, 50)
                                        ?.text
                                }</div>
                            </a>`;
                                this.textInput.setValue(htmlContent);
                                this.onSend(true);
                            })
                            .catch((error) => {
                                console.error(
                                    'Error converting image to Base64',
                                    error
                                );
                            });
                    }
                },
            });
    }

    processedText(): string {
        const text = this.textInput.value;

        // Regular expression to detect URLs and other content
        const urlPattern =
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])|(\bwww\.[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])|(\b[-A-Z0-9+&@#\/%?=~_|]+\.[A-Z]{2,}\b)/gi;

        // Replace URLs with anchor tags
        return text.replace(urlPattern, (url) => {
            let href = url;
            if (
                !href.startsWith('http') &&
                !href.startsWith('ftp') &&
                !href.startsWith('file')
            ) {
                href = 'http://' + url;
            }
            return `<a href="${href}" target="_blank">${url}</a>`;
        });
    }

    handleEnter(event: any) {
        event.preventDefault(); // Prevents going to a new line
        this.onSend();
    }

    async onSend(
        isMeeting: boolean = false,
        isConfirm: boolean = false,
        chatStatus: string = '0'
    ) {
        const now = new Date();
        const processedText = isMeeting
            ? this.textInput.value
            : this.processedText();
        // this.messages.push({
        //     text: processedText,
        //     file: {
        //         fileName: this.audioBlob ? this.audioName : this.fileName,
        //         file: this.audioBlob ? this.audioBlobUrl : this.base64Image,
        //         fileType: this.audioBlob ? 'audio/mp3' : this.fileType,
        //         isImage: this.fileType.startsWith('image/'),
        //         duration: this.audioBlob && this.audioRecordedTime,
        //     },
        //     isSend: true,
        //     isConfirm: isConfirm,
        //     time: this.date.transform(now, 'HH:mm aa'),
        // });
        if (this.textInput.value || this.file || this.audioBlob) {
            this.showSpinner = true;
            await this.submitChat(processedText, chatStatus);

            this.isSend = true;
            this.fileName = '';
            this.base64Image = '';
            this.isAudioRecording = false;
            this.audioBlobUrl = null;
            this.audioBlob = null;
            this.fileType = '';
            this.file = '';

            setTimeout(() => {
                this.textInput.setValue('');
                const textArea = document.getElementById('textarea');
                if (textArea) {
                    textArea.innerHTML = '';
                    textArea.style.height = '25px';
                    textArea.focus();
                }
                this.scrollToBottom();
            }, 200);
        }
    }

    showConfirm() {
        this.textInput.setValue('Are you happy with the conversion?');
        this.onSend(false, true);
    }

    closeConversion() {
        this.showFeedbackStars = true;
        // const dialogRef = this.dialog.open(FeedbackComponent, {
        //     width: '300px',
        //     maxWidth: '90vw',
        //     enterAnimationDuration: '200',
        //     exitAnimationDuration: '100',
        //     disableClose: true,
        // });

        // dialogRef.afterClosed().subscribe(async (res) => {
        //     this.textInput.setValue(
        //         'I am happy with the conversation. Thank You'
        //     );
        //     await this.onSend(false, false, '2');
        //     this.resetConversion.emit({
        //         status: false,
        //         feedback: res,
        //     });
        // });
    }

    continueChat() {
        this.textInput.setValue('No, I want to continue the chat.');
        this.onSend(false, false, '0');
    }

    async submitChat(processedText, chatStatus: string = '0') {
        const formData = new FormData();

        // Append text fields to FormData
        formData.append('description', processedText);
        formData.append('referenceId', this.chatRoomId);
        formData.append('referenceModule', 'supportChat');
        formData.append('tenant', this.withLogin? this.userDetails?.tenantid : '1');
        formData.append('status', 'true');
        formData.append('published', 'true');
        formData.append('isRead', 'false');
        formData.append('chatStatus', chatStatus);

        // Check if there's a file (either audio or image) and append it to FormData
        if (this.audioBlob) {
            // For audio file
            formData.append('fileName', this.audioName);
            formData.append('file', this.audioBlob, this.audioName); // Append the Blob directly
            formData.append('fileDuration', this.audioRecordedTime); // Append the Blob directly
            formData.append('documentType', 'audio/mp3'); // Append the Blob directly
            //formData.append('duration', this.audioRecordedTime?.toString());
        } else {
            // For image file
            formData.append('fileName', this.fileName);
            formData.append('file', this.file); // Assuming this is already a base64 string
            formData.append('documentType', this.fileType);
            //formData.append('fileType', this.fileType);
        }

        if(!this.withLogin){
            let fetchedGeneratedId = JSON.parse(this.localStorageService.getKey(StorageConst.cz_temGenId));
            formData.append('tempLoginId', fetchedGeneratedId);
            // formData.delete('file');
        }
        // const payload = {
        //     description: this.textInput.value,
        //     referenceId: this.chatRoomId,
        //     referenceModule: 'supportChat',
        //     tenant: [this.userDetails?.tenantid],
        //     status: 'true',
        //     published: 'true',
        //     isRead: 'false',
        // };
        let res:any;
       if(this.withLogin){
           res = await lastValueFrom(
               this._chatService
                   .sendMessage(formData)
                   .pipe(takeUntil(this._unsubscribeAll))
           );
       }else{
        res = await lastValueFrom(
            this._chatService
                .sendMessageWithOutlogin(formData)
                .pipe(takeUntil(this._unsubscribeAll))
        );
       }

        if (res.statusCode === 200 && res.success) {
            this.showSpinner = false;
        }
    }

    downloadPdf(base64String: any, fileName: string) {
        // window.open(base64String, '_blank');
        // return;
        // // const binaryString = window.atob(base64String);
        // // const len = binaryString.length;
        // // const bytes = new Uint8Array(len);

        // // for (let i = 0; i < len; i++) {
        // //     bytes[i] = binaryString.charCodeAt(i);
        // // }

        // // // Create a Blob from the bytes
        // // const blob = new Blob([bytes], { type: 'application/pdf' });

        // // Create a link element, hide it, direct it towards the blob, and then trigger it.
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = base64String;
        link.download = fileName;

        // Append to the document body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
    }

   async updateFeedback(id: number, startValue, clicked) {
        let clickedStar = this.totalFeebackStarts.find(star => star.id === id);
        if (clickedStar) {
            // If the star was already filled, unfill all stars after the clicked one
            if (clickedStar.filled) {
                // Unfill all stars from the clicked star onward
                this.totalFeebackStarts.forEach(star => {
                    star.filled = star.id <= clickedStar.id;
                });
                // Adjust feedback count but not below 1
                this.feedbackCount = clickedStar.id;
            } else {
                // If the star was not filled, fill up to the clicked star
                this.totalFeebackStarts.forEach(star => {
                    star.filled = star.id <= clickedStar.id;
                });
                // Adjust feedback count but not above 5
                this.feedbackCount = clickedStar.id;
            }
            // Ensure feedback count is between 1 and 5
            this.feedbackCount = Math.max(1, Math.min(this.feedbackCount, 5));
            this.textInput.setValue(
                'I am happy with the conversation. Thank You'
            );
            await this.onSend(false, false, '2');
            this.resetConversion.emit({
                status: false,
                feedback: '',
            });
        }
    }

    ngOnDestroy(): void {
        this.removeScript();
        this.removeEmojiListener();
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
