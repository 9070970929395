import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CustomizerSettingsService } from '../app/components/customizer-settings/customizer-settings.service';
import { ToggleService } from '../app/components/common/header/toggle.service';
import { LanguageService } from './core/services/languages/language.service';
import { countryAPI } from '../app/core/constants/api-constant/country-api';
import { HttpClientService } from '../app/core/services/httpClient/httpClient.service';
import { LoaderService } from './core/services/loader/loader.service';
import { Subject, takeUntil } from 'rxjs';
import { SocketService } from './core/services/socket/socket.service';
import { chatApi } from './core/constants/api-constant/chat-api.const';
import { LocalStorageService } from './core/services/localStorage/localStorage.service';
import { StorageConst } from './core/constants/storage.const';
import { generateRandomEightDigitNumber } from './core/helpers/helper.function';
import { InterceptorLoaderService } from './core/services/interceptor-loader.service';
import { LoaderInterceptorComponent } from './core/shared-modules/components/loader-interceptor/loader-interceptor.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(LoaderInterceptorComponent) loader: LoaderInterceptorComponent;

    title = 'Tagus - Material Design Angular Admin Dashboard Template';
    isToggled = false;
    lan: any = this.languageService.getLanguage('lang');
    private _unsubscribeAll: Subject<any> = new Subject();

    constructor(
        public router: Router,
        private httpClientService: HttpClientService,
        private toggleService: ToggleService,
        public themeService: CustomizerSettingsService,
        public languageService: LanguageService,
        private _loaderService: LoaderService,
        private _socketService: SocketService,
        private _localStorageService: LocalStorageService,
        private _loaderInterCeptorService: InterceptorLoaderService
    ) {
        this.toggleService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });

        const userDetails = JSON.parse(
            this._localStorageService.getKey(StorageConst.user_details)
        );

        const generateTempId = generateRandomEightDigitNumber();
        const userId = userDetails ? userDetails?.userId : generateTempId;
        if (!userDetails) {
            this._localStorageService.setKey(
                StorageConst.cz_temGenId,
                generateTempId.toString()
            );
        }

        if(userDetails){
            this._socketService.customTwoSocketOn('connect').subscribe((data: any) => {
                this._socketService.emitToChatChannel('application-notification', {
                    userId: String(userDetails?.userId),
                    userType: 'agent',
                });
            });
        }

        this._socketService.customSocketOn('connect').subscribe((data: any) => {
            this._socketService.emitToChannel(chatApi.supportChatChannel, {
                userId: String(userId),
                userType: 'agent',
            });
        });
        
    }

    ngOnInit(): void {
        // this.getCountryLocation();
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                window.scrollTo(0, 0);
            }
        });
    }

    getCountryLocation() {
        this._loaderService.showLoader();
        this.httpClientService
            .getLocation()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (res: any) => {
                    if (res.country_calling_code == '+86') {
                        this.setLanguageChinese();
                    }
                    this._loaderService.hideLoader();
                },
                (err) => {
                    this._loaderService.hideLoader();
                }
            );
    }

    setLanguageChinese() {
        this.languageService.setLanguage('zh-Hant');
    }

    toggleRightSidebarTheme() {
        this.themeService.toggleRightSidebarTheme();
    }

    toggleHideSidebarTheme() {
        this.themeService.toggleHideSidebarTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

    ngAfterViewInit(): void {
        this._loaderInterCeptorService.register(this.loader);
    }

    ngOnDestroy() {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
