import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { InterceptorLoaderService } from '../services/interceptor-loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private requestCount = 0;

    constructor(private loaderService: InterceptorLoaderService) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (this.requestCount === 0) {
            this.loaderService.show();
        }
        this.requestCount++;

        return next.handle(request).pipe(
            finalize(() => {
                this.requestCount--;
                if (this.requestCount === 0) {
                    this.loaderService.hide();
                }
            })
        );
    }
}
