import { Component } from '@angular/core';

@Component({
  selector: 'app-loader-interceptor',
  templateUrl: './loader-interceptor.component.html',
  styleUrls: ['./loader-interceptor.component.scss']
})
export class LoaderInterceptorComponent {
  isVisible = false;

  show(): void {
    this.isVisible = true;
  }

  hide(): void {
    this.isVisible = false;
  }
}
