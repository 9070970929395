import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class PopUpService {
    /**
     * Display confirmation popup on active screen
     * @param title(string) : title text of confirmation popup
     * @param message (string) : message/body text of confirmation popup
     * @param confirmButtonText (string) : confirm button text of confirmation popup
     * @param callback (function) : callback function, which will be triggered on user action
     */
    public showConfirmationPopup(
        title: string,
        message: string,
        confirmButtonText: string,
        callback: (confirmed: boolean) => void,
        isHTML: boolean = false,
        cancelButtonText: string | undefined = undefined
    ) {
        Swal.fire({
            title: title,
            text: !isHTML ? message : undefined, // Use text if not HTML
            html: isHTML ? message : undefined, // Use html if isHTML is true
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText ?? undefined,
        }).then((result) => {
            callback(result.isConfirmed);
        });
    }

    /**
     * Display series of popup to handle delete functionality : confirmation popup => loader => success message popup
     * @param successMessage (string) - optional : message to be shown on successful delete
     * @param onConfirm (function) : callback which is triggered on confirm button click
     * @param afterConfirm (function) - optional : callback which is triggered on close of success message popup
     */
    public showDeletePopup(
        successMessage: string | undefined = undefined,
        onConfirm: () => any,
        afterConfirm: () => any = () => {}
    ) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            confirmButtonText: 'Yes, delete it!',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            preConfirm: (confirmed: boolean): any => {
                if (confirmed) {
                    Swal.update({
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    Swal.showLoading();
                    return onConfirm.call(this);
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: 'Deleted!',
                        text: successMessage ?? 'Entity has been deleted.',
                        timer: 3000,
                        timerProgressBar: true,
                    }).then(() => {
                        afterConfirm.call(this);
                    });
                } else {
                    Swal.close();
                }
            })
            .catch((reason: any) => {
                Swal.fire({
                    text:
                        reason?.error?.errorMessage ??
                        'Error while deleting, please try again.',
                    icon: 'warning',
                    confirmButtonColor: 'rgb(3, 142, 220)',
                    confirmButtonText: 'OK',
                }).then(() => {
                    afterConfirm.call(this);
                });
            });
    }

    /**
     * Display success popup over active component
     * @param title (string): title text for success popup
     * @param message (string): message text for success popup
     */
    public showSuccessPopup(title: string, message: string) {
        Swal.fire(title, message, 'success');
    }

    /**
     * Display timer popup over active component
     * @param title (string): title text for timer popup
     * @param description (string): description test for timer popup
     * @param timerInSeconds (number): number of seconds popup will be shows before calling callback
     * @param showProgressBar (boolean): whether to show progress bar in timer popup
     * @param callback (function): callback function, which will be triggered after provided seconds interval
     */
    public showTimerPopup(
        title: string,
        description: string,
        timerInSeconds: number,
        showProgressBar: boolean = true,
        callback: () => void
    ) {
        let timerInterval: any;
        Swal.fire({
            title: title,
            html:
                description +
                '<br/><small> Will close in <b id="popup-timer"></b> milliseconds.</small>',
            timer: timerInSeconds,
            timerProgressBar: showProgressBar,
            didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                    const content = Swal.getHtmlContainer();
                    if (content) {
                        const b: any = content.querySelector('#popup-timer');
                        if (b) {
                            b.textContent = Swal.getTimerLeft();
                        }
                    }
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                callback();
            }
        });
    }

    /**
     * Display warning popup over active component
     * @param title (string): title text to be shown in warning popup
     * @param message (string): message text to be shown in warning popup
     */
    public showWarningPopup(
        title: string,
        message: string,
        callback?: () => void
    ): void {
        Swal.fire({
            title: title,
            text: message,
            icon: 'warning',
            confirmButtonColor: 'rgb(3, 142, 220)',
            confirmButtonText: 'OK',
        }).then(() => {
            if (callback) {
                callback();
            }
        });
    }
}
