import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  constructor(private socket: Socket) { }
  listenToChannel(channel: string): Observable<any> {
    return this.socket.fromEvent(channel);
  }

  // Emit data to a specific channel
  emitToChannel(channel: string, data: any) {
    this.socket.emit(channel, data);
  }

  // Optionally, disconnect from the socket server
  disconnect() {
    this.socket.disconnect();
  }

  // Reconnect to the socket server
  reconnect() {
    this.socket.connect();
  }
}
