export const environment = {
    baseURL: 'https://api-dev.crizac.com/v1/api/',
    fileBaseURL: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/system',
    callBaseURL: 'https://www.crizac.com/yeasterdownload/',
    validatePassportURL:
        'https://nps2entus1.execute-api.ap-south-1.amazonaws.com/default/ocr',
    currentLocationURL: 'https://ipapi.co/json/',
    basePHPURL: 'https://accounts.crizac.com/api_mongo',
    baseDevPHPURL: 'https://admin.crizac.com/apimongo',
    apiPHPURLv2: 'https://accounts.crizac.com/api_mongo/',
    paypalClientId:
        'AaNNahj68C8cdclPKQlL_e3ZWdyXTPT10p2uOcEahnnV3Ci2S2Sm_ry0cyQvqVbOn1w83fceC4claLty',
    paymentPercentage: 10,
    chatApi: 'wss://api-dev.crizac.com/support-chat-notification',
    notificationApi: "wss://api-console.crizac.com",
    redirectionPassword: 'crizac123',
    mailDomain: 'crizac.com',
    showCourses:true,
    showUniversity: true,
    showChat: true,
    showCompareCourse: false,
    showAdvanceFilter:false,
    APP_VERSION: "0.0.14"
};
