import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../localStorage/localStorage.service';
import { StorageConst } from '../../constants/storage.const';

@Injectable({
    providedIn: 'root',
})
export class HttpClientService {
    baseURL: string = environment.baseURL;
    currentLocationURL: string = environment.currentLocationURL;
    phpBaseURL: string = environment.basePHPURL;
    phpBaseDevURL: string = environment.baseDevPHPURL;
    phpBasev2: string = environment.apiPHPURLv2;

    constructor(
        private httpClient: HttpClient,
        private localStorageService: LocalStorageService
    ) {}

    private getHeaderAuth() {
        const token = this.localStorageService.getKey(StorageConst.access_token);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            }),
        };
        return httpOptions;
    }

    private getHeaderBasicAuth() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Basic ' + btoa('akorn:akorn'),
            }),
        };
        return httpOptions;
    }

    private  getHeaderAuthUpload() {
        const token = this.localStorageService.getKey(StorageConst.access_token);
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + token,
            }),
        };
        return httpOptions;
    }

    private getHeaderBasicAuthUpload() {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Basic ' + btoa('akorn:akorn'),
            }),
        };
        return httpOptions;
    }

    private getPasswordResetAuth(token:string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            }),
        };
        return httpOptions;
    }

    public getResetPasswordAuth(url: string, payload: any) {
        let httpOptions = this.getPasswordResetAuth(payload.token);
        delete payload.token;
        return this.httpClient.post<unknown>(
            `${this.baseURL}${url}`,
            payload,
            httpOptions
        );
    }

    public get(url: string, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        return this.httpClient.get<unknown>(
            `${this.baseURL}${url}`,
            httpOptions
        );
    }

    public post(url: string, payload: unknown, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        return this.httpClient.post<unknown>(
            `${this.baseURL}${url}`,
            payload,
            httpOptions
        );
    }

    public put(url: string, payload: unknown, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        return this.httpClient.put<unknown>(
            `${this.baseURL}${url}`,
            payload,
            httpOptions
        );
    }

    public patch(url: string, payload: unknown, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        return this.httpClient.patch<unknown>(
            `${this.baseURL}${url}`,
            payload,
            httpOptions
        );
    }

    public delete(url: string, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        return this.httpClient.delete<unknown>(
            `${this.baseURL}${url}`,
            httpOptions
        );
    }

    public upload(url: string, payload: unknown, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuthUpload();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuthUpload();
        }
        return this.httpClient.post<unknown>(
            `${this.baseURL}${url}`,
            payload,
            httpOptions
        );
    }

    public getLocation() {
        return this.httpClient.get<unknown>(`${this.currentLocationURL}`);
    }

    public csv(url: string) {
        let httpOptions = this.getHeaderAuthUpload();
        return this.httpClient.get(`${this.baseURL}${url}`, {...httpOptions, responseType: 'blob' });
    }



    // PHP API
    public getPhp(url: string, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        return this.httpClient.get<unknown>(
            `${this.phpBaseURL}${url}`,
            httpOptions
        );
    }


    // DevPHP API
    public getPhpDev(url: string, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        return this.httpClient.get<unknown>(
            `${this.phpBaseDevURL}${url}`,
            httpOptions
        );
    }

    // DevPHP API
    public postPhpDev(url: string, payload, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        return this.httpClient.post<unknown>(
            `${this.phpBaseDevURL}${url}`,
            payload,
            httpOptions
        );
    }

    //spclpostforautologin
    public autologinpost(url: string, payload: unknown, headers) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Basic ' + btoa('akorn:akorn'),
                'username': headers?.username,
                'password': headers?.password
            }),
        };
        return this.httpClient.post<unknown>(
            `${this.baseURL}${url}`,
            payload,
            httpOptions
        );
    }

    //spclpostforautologinforadmin
    public autologinpostforadmin(url: string, payload: any, headers) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Basic ' + btoa('akorn:akorn'),
                // "email": "nilavo.dutta.2000@gmail.com", 
                // "tenantId":"1",
                // "userName":"agentPortal",
                "password": headers.password
            }),
        };
        return this.httpClient.post<unknown>(
            `${this.baseURL}${url}`,
            payload,
            httpOptions
        );
    }

     // DevPHP API
     public postPhpDevv2(url: string, payload, isBasicAuth: boolean = false) {
        let httpOptions = this.getHeaderBasicAuth();
        if (!isBasicAuth) {
            httpOptions = this.getHeaderAuth();
        }
        return this.httpClient.post<unknown>(
            `${this.phpBasev2}${url}`,
            payload,
            httpOptions
        );
    }

}
