import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  // transform(value: string, limit: number): string {
  //   if (!value) return '';
  //   if (value.length <= limit) return value;
  //   return value.substring(0, limit) + '...';
  // }
  transform(value: string, limit: number): { text: string, truncated: boolean } {
    if (!value) return { text: '', truncated: false };
    const truncated = value.length > limit;
    const text = truncated ? value.substring(0, limit) + '...' : value;
    return { text, truncated };
  }

}
