import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { SpecialCharPipe } from './core/pipe/special-char.pipe';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { Socket } from 'ngx-socket-io';
import { ChatModule } from './views/chat/chat.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { environment } from 'src/environments/environment';
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';
import { LoaderInterceptorComponent } from './core/shared-modules/components/loader-interceptor/loader-interceptor.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

// const config: SocketIoConfig = {
//     url: environment.chatApi,
//     options: {
//         transports: ['websocket'],
//     },
// };

@Injectable()
export class SocketChat extends Socket {
    constructor() {
        super({
            url: environment.chatApi,
            options: {
                transports: ['websocket'],
            },
        });
    }
}

@Injectable()
export class SocketNotification extends Socket {
    constructor() {
        super({
            url: environment?.notificationApi,
            options: {
                transports: ['websocket'],
            },
        });
    }
}

@NgModule({
    declarations: [AppComponent, LoaderInterceptorComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CarouselModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        ToastrModule.forRoot({ preventDuplicates: true }),
        ChatModule,
        // SocketIoModule.forRoot(config),
        SweetAlert2Module.forRoot(),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
        DatePipe,
        SpecialCharPipe,
        SocketNotification,
        SocketChat,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
