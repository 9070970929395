import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../httpClient/httpClient.service';
import { ApplicationAPI } from '../../constants/api-constant/appilication-api.const';
import { LoaderService } from '../loader/loader.service';
import { PaginationType } from '../../interfaces/pagination/pagination.interface';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private applicationUrl = environment.baseURL;
  private appPhpV2 = environment.apiPHPURLv2;
  private _applicationDetails: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
  fetchCommentClicked = new BehaviorSubject({});
  constructor(
    private _httpClientService: HttpClientService,
    private _loaderService: LoaderService,
    private http: HttpClient
  ) { }

  getApplicationHistory(pagination: any, university_country: any, search: any, filters: any): Observable<any> {
    let query = '?';
    if (university_country) {
      query += `universityCountry=${university_country}&`;
    }
    if (search) {
      query += `${search}`;

    }
    if (Object.keys(filters).length > 0) {
      query += filters;
    }

    query += `skip=${pagination.skip}&limit=${pagination.limit}`;

    return this._httpClientService.get(`${ApplicationAPI.getApplicationHistory}${query}`);
  }

  addApplication(payload: any): Observable<any> {
    return this._httpClientService.post(ApplicationAPI.addApplication, payload);
  }

  getUploadDocuments(pagination: PaginationType, id: any): Observable<any> {
    let query = `skip=${pagination.skip}&limit=${pagination.limit}`;
    return this._httpClientService.get(`${ApplicationAPI.getUploadDocuments}${id}&internalStatus=true&${query}`);
  }

  uploadDoc(payload: any): Observable<any> {
    return this._httpClientService.upload(ApplicationAPI.uploadDoc, payload);
  }

  getAppFilters(university_country: any): Observable<any> {
    let query = '?';
    if (university_country) {
      query += `universityCountry=${university_country}`;
    }
    return this._httpClientService.get(`${ApplicationAPI.getAppFilters}${query}`);
  }

  verifyPassport(payload: any): Observable<any> {
    return this._httpClientService.upload(ApplicationAPI.verifyPassport, payload);
  }

  syncVerifyPassword(payload: any): Observable<any> {
    return this._httpClientService.post(ApplicationAPI.syncVerifyPassword, payload);
  }

  get applicationDetails$(): Observable<any[]> {
    return this._applicationDetails.asObservable();
  }

  getApplicationById(id: any): Observable<any> {
    this._loaderService.showLoader();
    return this._httpClientService.get(`${ApplicationAPI.getApplicationById}${id}`)
      .pipe(
        tap((response: any) => {
          if (response && response?.statusCode === 200) {
            this._applicationDetails.next(response?.data[0]);
          }
          this._loaderService.hideLoader();
        }, (err) => {
          this._loaderService.hideLoader();
        })
      )
  }

  getCallRecordings(applicationID: any): Observable<any> {
    return this._httpClientService.get(`${ApplicationAPI.getCallRecordings}${applicationID}`);
  }

  getComments(pagination: PaginationType, id: any): Observable<any> {
    let query = `skip=${pagination.skip}&limit=${pagination.limit}`;
    return this._httpClientService.get(`${ApplicationAPI.getComments}${id}?notifyAgent=true&${query}`);
  }

  getcommentStatuses(pagination: PaginationType): Observable<any> {
    let query = `?skip=${pagination.skip}&limit=${pagination.limit}`;
    return this._httpClientService.get(`${ApplicationAPI.getcommentStatuses}${query}`);
  }

  postComment(payload: any): Observable<any> {
    return this._httpClientService.post(ApplicationAPI.postComment, payload);
  }

  getMailCommunication(applicationId: any) {
    return this._httpClientService.get(`${ApplicationAPI.getMailCommunication}${applicationId}&agentStatus=true`)
  }

  getMailDetails(msgId: any) {
    return this._httpClientService.post(`${ApplicationAPI.getMailDetails}${msgId}`, {})
  }

  instantApply(payload: any): Observable<any> {
    return this._httpClientService.post(`${ApplicationAPI.instantApply}`, payload);
  }

  createDoc(payload: any): Observable<any> {
    return this._httpClientService.post(`${ApplicationAPI.createDoc}`, payload);
  }

  updateApplication(appId: any, payload: any): Observable<any> {
    return this._httpClientService.put(`${ApplicationAPI.updateApplication}${appId}`, payload);
  }

  getApplictionHistortId(id: number): Observable<any> {
    const url = `${this.applicationUrl}/application/getById/${id}`;
    return this._httpClientService.get(url);
  }

  getApplictionHistortById(id: number): Observable<any> {
    const url = `/application/getById/${id}`;
    return this._httpClientService.get(url);
  }

  extractStudentData(pagination: PaginationType, filters?: any): Observable<any> {
    let query = '?';
    if (filters) {
      query += filters;
    }
    query += `skip=${pagination.skip}&limit=${pagination.limit}`;

    return this._httpClientService.csv(`${ApplicationAPI.extractStudentData}${query}`);
  }

  // MOU Service
  getEmailTemplateMOU(agentId: any) {
    //? /emailTemplates/getMouTemplate?tenantId=1&eventTypeId=website-MOU&agentId=1704894754
    return this.http.post(
      this.applicationUrl + `/emailTemplates/getMouTemplate?tenantId=1&eventTypeId=website-MOU&agentId=${agentId}`, {});
  }

  updateMOU(onRoleStatus: string, agentId: any, payload: Object) {
    return this.http.put(
      this.applicationUrl + `/agents/onRoleStatus?onRoleStatus=${onRoleStatus}&id=${agentId}`, payload);
  }

  phpGetApplicationFiles(userId, camsId) : Observable<any> {
    return this.http.get(
      this.appPhpV2 + `/order_application_file.php?user_id=${userId}&order_id=${camsId}`
    );
  }

  phpGetApplicationComments(userId, camsId) : Observable<any> {
      return this.http.get(
        this.appPhpV2 + `/order_application_comment.php?user_id=${userId}&order_id=${camsId}`
      );
  }

  phpGetApplicationMails(userId, camsId) : Observable<any> {
    return this.http.get(
       this.appPhpV2 + `/migration_university_communication.php?user_id=${userId}&order_id=${camsId}`
    );
}

}
