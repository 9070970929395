<div class="chat-feedback-container">
    <div
        mat-dialog-title
        class="d-flex justify-content-space-between px-4 py-4"
    >
        <h6 class="m-0 w-100 fs-16">Feedback</h6>
        <span class="material-symbols-outlined cursor-pointer" mat-dialog-close>close</span>
    </div>
    <mat-dialog-content>
        <div class="row py-4">
            <div class="col-12">
                <div class="tagus-form-group">
                    <mat-form-field appearance="fill">
                        <mat-label>Write some feedback...</mat-label>
                        <textarea
                            matInput
                            rows="6"
                            [formControl]="feedback"
                        ></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button
            mat-flat-button
            type="button"
            class="tagus fw-semibold d-block ms-5 d-flex justify-content-end"
            matBadgePosition="after"
            matBadgeColor="accent"
            (click)="submitFeedback()"
        >
            Submit
        </button>
    </mat-dialog-actions>
</div>
